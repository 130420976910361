@import '~styles/variables';
@import '~styles/helpers';

.PasswordInput {
  position: relative;
  max-width: 300px;

  input {
    height: $form-element-height;
    width: 100%;
    max-width: $form-element-max-width;
    min-width: 300px;
    border: 1px solid $form-element-border;
    outline: none;
    padding: 0 10px;
    color: $text-color-primary;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;

    &:focus {
      border: 1px solid $form-element-border-focus;
    }

    &:disabled {
      background: $form-element-disabled-background;
      border-color: $form-element-disabled-border;
    }

    &.has-error {
      border-color: $red;
    }
  }

  .Spinner {
    width: 60px;
    right: 30px;
  }

  button {
    @include appearance(none);
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
  }
}
