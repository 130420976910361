@import '~styles/variables';
@import '~styles/helpers';

.Header {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  height: $Header-height;
  box-shadow: $shadow;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;

  .Header-primary {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      display: flex;
      align-items: center;
      margin: 0 15px;

      a {
        @include flexMiddleCenter;
        text-decoration: none;
        font-weight: 600;
        color: $white;

        .company-logo {
          margin-right: 10px;
          width: 35px;
          height: auto;
          max-height: 35px;
        }

        .company-logo-placeholder {
          width: 30px;
          height: 30px;
          background: $white;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 26px;
          font-weight: 600;
          border-radius: 2px;
          color: $text-color-primary;
        }
      }
    }
  }

  .Header-secondary {
    display: flex;
    align-items: center;
    margin-right: 30px;

    .user-company {
      margin-right: 40px;
      color: $white;
      font-weight: 500;
      font-size: 14px;
    }

    .Selector {
      margin-right: 40px;
      min-width: 100px;
      height: 26px;
      display: flex;
      align-items: center;

      .selector-container {
        width: 100%;
      }

      .selector-inner__control {
        min-height: 26px;
      }
    }
  }
}
