@import '~styles/variables';

.Dropdown {
  position: absolute;
  top: 30px;
  z-index: 90;
  right: 0;
  white-space: nowrap;
  background-color: $white;
  padding: 10px 15px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 5px solid $white;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    right: 10px;
    top: -5px;
  }

  & > ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      cursor: pointer;

      .li-content {
        border: 0;
        padding: 5px 0;
        font-size: 14px;
        display: block;
        text-decoration: none;
        color: $text-color-primary;
      }

      &:last-of-type {
        .li-content {
          padding-bottom: 0;
        }
      }
    }
  }
}
