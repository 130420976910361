@import '~styles/variables';

.ProfileNavigation {
  position: relative;

  .profile-label {
    display: flex;
    align-items: center;
    line-height: 20px;
    font-size: 12px;
    font-weight: 600;
    z-index: 36;
    opacity: 0.8;
    cursor: pointer;
    transition:
      background-color 0.2s ease-in-out,
      opacity 0.2s ease-in-out,
      transform 0.2s ease-in-out;
    text-align: center;

    .sidebar-item {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }
  }

  .Dropdown {
    padding: 0;

    button {
      text-align: start;
      width: 100%;
      background-color: transparent;
    }

    .li-content {
      cursor: pointer;
      outline: none;
      padding: 10px 15px !important;

      &:hover {
        background-color: $option-hover-background;
      }
    }
  }
}
