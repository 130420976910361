@import '~styles/variables';
@import '~styles/helpers';

.Modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(#000, 0.5);
  z-index: 99;
  color: $text-color-primary;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 50px 0;

  .modal-inner {
    position: relative;
    background-color: $white;
    box-shadow: 1px 1px 10px rgba(#000, 0.2);
    max-width: 1000px;

    &-size-sm {
      width: 30vw;
      min-width: 400px;
      max-width: 500px;

      @media screen and (max-width: 800px) {
        min-width: unset;
      }
    }

    &-size-md {
      width: 40vw;
      min-width: 500px;
      max-width: 600px;

      @media screen and (max-width: 800px) {
        min-width: unset;
      }
    }

    &-size-lg {
      width: 55vw;
      min-width: 600px;
      max-width: 850px;

      @media screen and (max-width: 800px) {
        min-width: unset;
      }
    }

    @media screen and (max-width: 800px) {
      width: 100%;
    }

    .modal-header {
      padding: 20px 10px 20px 20px;
      font-size: 24px;
      font-weight: 500;
    }

    .modal-body {
      padding: 0 20px 20px;
      background-color: $white;
    }

    .modal-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      background-color: lighten($gray, 50%);
      padding: 5px 20px;

      &.hidden {
        display: none;
      }

      button {
        margin-right: 10px;
      }
    }

    .modal-dismiss {
      position: absolute;
      top: 10px;
      right: 0;
      background: transparent;
      border: none;
      font-size: 15px;
      cursor: pointer;
      opacity: 0.5;
      font-weight: 600;
      outline: 0;

      &:hover {
        opacity: 1;
      }
    }
  }
}
