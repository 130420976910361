// Colors
$blue: #2e86de;
$green: #10ac84;
$gray: #555555;
$orange: #ff9f43;
$red: #ee5253;
$yellow: #feca57;
$white: #ffffff;
$background: #fafafa;
$black: #222222;

// Text definition
$text-color-primary: #464854;
$text-color-secondary: lighten($text-color-primary, 5);
$text-color-error: darken($red, 12);
$font-family: 'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif;

$shadow: 1px 1px 4px rgba(#000, 0.1);

$Header-height: 50px;

// form elements
$form-element-border: #e3e3e3;
$form-element-border-focus: #44486c;
$form-element-max-width: 300px;
$form-element-height: 34px;
$form-element-disabled-background: #f5f5f5;
$form-element-disabled-border: #ccc;

$select-option-hover: lighten($blue, 20%);
$option-hover-background: #eee;
$option-selected-background: lighten($blue, 30%);

$default-transition: 150ms all ease-in-out;
