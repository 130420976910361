@import '~styles/helpers';
@import '~styles/variables';

a {
  color: inherit;
}

.list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;

  & > div {
    display: flex;

    &:first-of-type {
      .Button {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }

    &:last-of-type {
      .Button {
        margin-left: 10px;
        margin-bottom: 0;
      }
    }
  }
}

::-webkit-scrollbar {
  height: 16px;
  overflow: visible;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px 1px 1px 6px;
  min-height: 28px;
  padding: 100px 0 0;
  box-shadow:
    inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}

::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px;
}
