@import '~styles/variables';

.TextArea {
  position: relative;

  textarea {
    width: 100%;
    min-width: 300px;
    border: 1px solid $form-element-border;
    outline: none;
    padding: 10px;
    color: $text-color-primary;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    resize: none;
    font-family: $font-family;

    &:focus {
      border: 1px solid $form-element-border-focus;
    }

    &:disabled {
      background: $form-element-disabled-background;
      border-color: $form-element-disabled-border;
    }

    &.has-error {
      border-color: $red;
    }
  }
}
