@import '~styles/variables';
@import '~styles/helpers';

.MultiEmailInput {
  background-color: $white;
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  border: 1px solid $form-element-border;
  outline: none;
  padding: 10px;
  color: $text-color-primary;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;

  &:focus {
    border: 1px solid $form-element-border-focus;
  }

  &:disabled {
    background: $form-element-disabled-background;
    border-color: $form-element-disabled-border;
  }

  &.has-error {
    border-color: $red;
  }

  .input {
    @include appearance(none);
    height: $form-element-height;
    width: 100%;
    max-width: 400px;
    min-width: 300px;
    border: none;
    outline: none;
    padding: 0 10px 0 0;
    color: $text-color-primary;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
  }

  .tag-item {
    background-color: #d4d5d6;
    font-size: 12px;
    padding: 2px 0px 2px 6px;
    display: inline-flex;
    align-items: center;
    margin: 0 8px 4px 0;
    border-radius: 2px;
    word-break: break-word;

    button {
      @include appearance(none);
      background-color: transparent;
      width: 22px;
      height: 22px;
      border: none;
      cursor: pointer;
      margin-left: 10px;
      font-weight: bold;
      padding: 0;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
