@import '~styles/variables';

.DescriptionTable {
  margin: 0 0 20px;
  background: #fff;
  border: 1px solid $form-element-border;
  padding: 10px 15px;
  font-size: 13px;
  color: $text-color-primary;
  font-weight: 500;
  position: relative;

  table {
    width: 100%;
    border-collapse: collapse;

    tr {
      vertical-align: baseline;

      td {
        width: 70%;
        word-break: break-word;
        box-sizing: border-box;
        padding: 6px 2px;
        border-bottom: 1px solid rgba($form-element-border, 0.5);

        &:first-of-type {
          width: 30%;
          min-width: 100px;
        }

        &:last-of-type {
          color: $text-color-secondary;
          font-weight: 400;
        }

        .Button {
          margin: 0;
        }

        .tooltip-header {
          display: flex;

          span {
            margin-left: 5px;
          }
        }
      }

      &:last-of-type {
        td {
          border: 0;
        }
      }

      &.warning {
        td {
          color: $red;
        }
      }

      &.success {
        td {
          color: $green;
        }
      }
    }
  }

  .ContentLoader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: #ededed47;
  }
}
