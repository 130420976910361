.ExistingUserForm {
  &-message {
    text-align: left;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .DescriptionTable {
    margin-bottom: 10px;
    text-align: left;
  }

  .license-manager-input {
    display: flex;
  }
}
