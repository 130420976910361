.oauth-actions {
  width: 100%;

  .credential-cell {
    cursor: default;
    display: flex !important;
    align-items: center !important;
    height: 100% !important;
  }

  .credential-actions {
    max-width: 120px;
    cursor: default;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .icon-button {
    background-color: transparent;
    cursor: pointer;
    padding: 8px;
    border-radius: 9999px;
    border-color: transparent;
    transition: colors 0.2s ease-in-out;

    svg {
      width: 20px;
      height: 20px;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &--refresh {
      color: #059669; // green-600 equivalent
      &:hover:not(:disabled) {
        color: #047857; // green-700 equivalent
      }
    }

    &--rotate {
      color: #4b5563; // gray-600 equivalent
      &:hover:not(:disabled) {
        color: #374151; // gray-700 equivalent
      }
    }

    &--delete {
      color: #dc2626; // red-600 equivalent
      &:hover:not(:disabled) {
        color: #b91c1c; // red-700 equivalent
      }
    }
  }
}

.oauth-client-secret-actions {
  display: flex;
  justify-content: justify-start;
  gap: 1rem;
}

.row {
  margin-bottom: 0;
}

.credential-cell {
  .credential-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.secret-expiration-info {
  margin-top: 4px;

  .expiration-row {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding: 8px;
    border-radius: 4px;
    background-color: #f5f5f5;

    &.status-normal {
      background-color: #f0f7ff;
      color: #0066cc;
    }

    &.status-warning {
      background-color: #fff7e6;
      color: #d46b08;
    }

    &.status-expired {
      background-color: #fff1f0;
      color: #cf1322;
    }
  }

  .expiration-icon {
    width: 16px;
    height: 16px;
    margin-top: 2px;
  }

  .expiration-details {
    flex: 1;
  }

  .expiration-main {
    font-weight: 500;
    margin-bottom: 2px;
  }

  .expiration-secondary {
    font-size: 12px;
    opacity: 0.85;
  }

  .rotation-info {
    margin-top: 4px;
    font-size: 12px;
    font-style: italic;
  }
}
