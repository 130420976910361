@import '~styles/variables';

.Expander {
  .ExpanderItemButton {
    display: flex;
    align-items: center;
    width: unset;
    background-color: #f5f5f5;
    border-top: 2px solid #eaeaea;
    font-weight: 500;

    &:hover {
      background-color: $option-hover-background;
    }

    &::before {
      margin-right: 24px;
    }
  }

  .ExpanderItemPanel {
    animation-duration: 100ms;
  }
}
