@import '~styles/variables';

.StatusCard {
  display: inline-flex;
  align-items: center;
  color: inherit;

  & > span {
    margin-right: 7px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
  }

  &-success {
    background: $green;
  }

  &-warning {
    background: $yellow;
  }

  &-error {
    background: $red;
  }
}
