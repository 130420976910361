@import '~styles/variables';

.DatePicker {
  .SingleDatePicker {
    &Input {
      border: none;
      max-width: $form-element-max-width;
      width: 100%;
    }

    .DateInput {
      border: 0;
      width: 100%;

      &_input {
        height: $form-element-height;
        padding: 0 10px;
        font-size: 14px;
        border: 1px solid $form-element-border;

        &:focus {
          outline: 0;
          border: 1px solid $form-element-border-focus;
        }

        &__focused {
          border: 1px solid $form-element-border-focus;
        }
      }
    }
  }
}
