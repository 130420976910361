.LicenseUsers {
  .list-header {
    margin-bottom: 0;
  }

  .max-license-users-count {
    font-weight: 500;
    font-size: 14px;
    background: #ffffff;
    padding: 5px 18px;
    border: 1px solid #eaeaea;
    display: flex;
    align-items: center;
  }
}
