@import '~styles/globals';
@import '~styles/variables';

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  color: $text-color-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
}
