.BorrowLicenseForm {
  .row {
    .DateInput {
      max-width: unset;
      width: 100%;

      input {
        max-width: unset;
      }
    }
  }
}
