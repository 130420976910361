@import '~styles/variables';
@import '~styles/helpers';

.Button {
  @include hasFocus;
  margin: 10px 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid transparent;
  color: $white;
  text-align: center;
  font-size: 16px;
  color: #fff;
  outline: 0;
  cursor: pointer;
  transition: $default-transition;

  .ContentLoader {
    padding: 0;

    .ContentLoader-spinner {
      margin-right: 10px;
      border-top-color: $white;
    }

    .ContentLoader-text {
      display: none;
    }
  }

  &-size-sm {
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    height: 24px;
    padding: 0 20px;

    .ContentLoader-spinner {
      width: 10px;
      height: 10px;
      border-width: 1px;
    }
  }

  &-size-md {
    border-radius: 3px;
    font-size: 13px;
    font-weight: 500;
    height: $form-element-height;
    padding: 0 30px;

    .ContentLoader-spinner {
      width: 13px;
      height: 13px;
      border-width: 1px;
    }
  }

  &-size-lg {
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    height: 44px;
    padding: 0 40px;

    .ContentLoader-spinner {
      width: 15px;
      height: 15px;
      border-width: 1px;
    }
  }

  &-theme-default {
    background: $white;
    border: 1px solid #ccc;
    color: #555;

    .ContentLoader-spinner {
      border-top-color: $text-color-primary !important;
    }
  }

  &-theme-info {
    background: $blue;
    border: 1px solid darken($blue, 8);
  }

  &-theme-success {
    background: $green;
    border: 1px solid darken($green, 8);
  }

  &-theme-warning {
    background: $orange;
    border: 1px solid darken($orange, 8);
  }

  &-theme-error {
    background: $red;
    border: 1px solid darken($red, 8);
  }

  &-theme-link {
    background: transparent;
    border: none;
    color: $text-color-primary;
    text-transform: initial;
    box-shadow: none;

    .ContentLoader-spinner {
      border-top-color: $text-color-primary !important;
    }
  }

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;

    &:hover {
      opacity: 0.3;
    }
  }
}
