@import '~styles/variables';

.SelectBundleFormTitle {
  display: flex;
  align-items: center;

  .confirm-btn {
    margin-left: auto;
  }
}

.SelectBundleForm {
  .bundle-prod-warning {
    display: flex;
    align-items: center;

    span {
      margin-right: 4px;
    }
  }

  .DescriptionTable {
    width: 100%;
    margin-top: -20px;
  }

  .section-row {
    margin-bottom: 20px;

    .bundle-products-loading {
      margin-top: 60px;
    }

    .bundle-products-list {
      .bundle-products-heading {
        font-weight: 600;
        border-bottom: 1px solid #eaeaea;
        margin-bottom: 24px;
        padding-bottom: 10px;
      }
    }
  }
}
